import axios from 'axios';
import { useMemo, useState } from 'react';
import useSWR from 'swr';
import useCluster from '@/hooks/useCluster';
import usePagination from '@/hooks/usePagination';
import { InstanceDetails } from '@/types/Instance';
import moment from 'moment';
import { sortArrayByField } from '@/helper/utils';

const useClusterInstanceList = () => {
  const {
    clusterId,
    isClusterReady,
    isLoading: isClusterLoading,
  } = useCluster();
  const { pageIndex, pageSize, gotoPage } = usePagination();

  const [params, setParams] = useState<TableParams>();

  const swrKey = useMemo(
    () => (isClusterReady ? `/clusters/${clusterId}/instances` : null),
    [isClusterReady]
  );

  const { data, error, mutate, isValidating, isLoading } = useSWR<
    ListRes<InstanceDetails>
  >(swrKey, (url) =>
    axios.get(url).then((res) => {
      res.data.payload.list = res.data.payload.list.map(
        (item: {
          last_seen_time: moment.MomentInput;
          register_time: moment.MomentInput;
        }) => ({
          ...item,
          uptime: moment(item.last_seen_time).diff(moment(item.register_time)),
        })
      );
      return res.data.payload;
    })
  );

  const sortDataSource = useMemo(
    () =>
      params?.order_by && params?.direction
        ? sortArrayByField(data?.list || [], params.order_by, params.direction)
        : data?.list,
    [params?.order_by, params?.direction, data]
  );

  const returnData = useMemo(
    () => ({
      list: sortDataSource?.slice(
        pageIndex * pageSize,
        pageIndex * pageSize + pageSize
      ),
    }),
    [pageIndex, pageSize, sortDataSource]
  );

  const onParamsChange = (newParams: TableParams) => {
    setParams(newParams);
  };

  const reload = () => {
    gotoPage(0);
    mutate();
  };

  return {
    data: returnData?.list,
    pagination: {
      total: data?.count,
      pageIndex,
      pageSize,
      gotoPage,
    },
    isLoading: isLoading || isClusterLoading,
    isValidating,
    isError: error,
    reload,
    onParamsChange,
  };
};

export default useClusterInstanceList;
