import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from '@chakra-ui/react';

const StatusHelperModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader
        fontSize="lg"
        fontWeight="medium"
        lineHeight="7"
        color="A7Gray.700"
      >
        What is Status?
      </ModalHeader>
      <ModalBody fontSize="sm" lineHeight="5" color="A7Gray.700">
        <p>
          Status represents the connectivity between your gateway instances
          (Apache APISIX) and API7 Cloud.
        </p>
        <br />
        <p>Healthy means the running status of the instance is normal.</p>
        <br />
        <p>
          Only Heartbeat means that although the instance keeps connected with
          API7 Cloud by the heartbeat probes, the configuration delivery fails.
        </p>
        <br />
        <p>
          Lost Connection means the last time API7 Cloud saw this instance had
          been more than 30s but less than 2 hours.
        </p>
        <br />
        <p>
          Offline means the elapsed time since API7 Cloud has seen this instance
          is more than 2 hours. In such a case, API7 Cloud will remove the
          instance entry after seven days if the instance still doesn&apos;t
          connect to API7 Cloud.
        </p>
        <br />
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="A7Black" onClick={onClose}>
          OK
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default StatusHelperModal;
