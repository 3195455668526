import { Flex, Grid, GridItem, Box } from '@chakra-ui/react';
import Skeleton from 'react-loading-skeleton';

const OverviewSkeleton = () => (
  <Flex border="1px #F0F0F0 solid" borderRadius="4px" width="fit-content">
    <Grid templateColumns="repeat(4, 1fr)" gap={0}>
      {['1', '2', '3', '4'].map((item, index) => (
        <GridItem
          height="172px"
          width="276px"
          padding="40px 20px 40px 20px"
          borderLeft={index === 0 ? 'none' : '1px #F0F0F0 solid'}
          key={item}
        >
          <Skeleton height="20px" width="100px" />
          <Flex
            height="48x"
            width="226px"
            justifyContent="space-between"
            mt="10px"
          >
            <Flex fontSize="40px">
              <Skeleton height="20px" width="20px" />
            </Flex>
            <Flex
              pb="10px"
              color="A7Gray.500"
              fontSize="lg"
              lineHeight="5"
              textAlign="right"
              flexDirection="column-reverse"
            >
              <Skeleton height="20px" width="50px" />
            </Flex>
          </Flex>
          <Box mt="10px">
            <Skeleton height="4px" width="226px" />
          </Box>
        </GridItem>
      ))}
    </Grid>
  </Flex>
);

export default OverviewSkeleton;
