import { Box, Flex } from '@chakra-ui/react';
import Skeleton from 'react-loading-skeleton';

const ClusterSkeleton = () => (
  <Box border="1px #F0F0F0 solid" borderRadius="4px" width="full">
    {['1', '2', '3'].map((item, index) => (
      <Flex
        height="56px"
        backgroundColor={index % 2 !== 0 ? 'A7Gray.50' : 'none'}
        key={item}
      >
        <Flex
          alignItems="center"
          ml="24px"
          w="172px"
          color="A7Gray.800"
          lineHeight="6"
        >
          <Skeleton width="120px" />
        </Flex>
        <Flex alignItems="center" color="A7Gray.900" fontWeight="medium">
          <Skeleton width="200px" />
        </Flex>
      </Flex>
    ))}
  </Box>
);

export default ClusterSkeleton;
