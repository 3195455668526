import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';

import { nFormatter } from 'helper/utils';

const Progress: React.FC<{ currentValue: number; total: number }> = ({
  currentValue,
  total,
}) => {
  const percentage =
    (currentValue / total > 1 ? 1 : currentValue / total) * 100;

  return (
    <Box
      mt="10px"
      backgroundColor="A7Gray.100"
      height="4px"
      w={['166px', '226px', '226px', '226px']}
    >
      <Box
        height="4px"
        backgroundColor={percentage >= 80 ? '#ECC94B' : 'A7Gray.800'}
        width={`${percentage}%`}
      />
    </Box>
  );
};

export type Props = {
  name: string;
  currentValue: number;
  total?: number;
  unit?: string;
  content?: string;
};

const OverviewPlane: React.FC<{ data: Props[] }> = ({ data = [] }) => (
  <Flex border="1px #F0F0F0 solid" borderRadius="4px" width="fit-content">
    <Grid templateColumns="repeat(4, 1fr)" gap={0}>
      {data.map((item, index) => (
        <GridItem
          height="172px"
          width={['226px', '276px', '276px', '276px']}
          padding="40px 20px 40px 20px"
          borderLeft={index === 0 ? 'none' : '1px #F0F0F0 solid'}
          key={item.name}
        >
          <Box lineHeight="5" fontSize="sm">
            {item.name}
          </Box>
          <Flex
            height="48x"
            width={['166px', '226px', '226px', '226px']}
            justifyContent="space-between"
            mt="10px"
          >
            <Flex fontSize="40px">
              {nFormatter(item.currentValue, 2)}
              {item.unit}
            </Flex>
            {item.total && (
              <Flex
                pb="10px"
                color="A7Gray.500"
                fontSize="lg"
                lineHeight="5"
                textAlign="right"
                flexDirection="column-reverse"
              >
                {item.content ? item.content : 'Max'}:{' '}
                {nFormatter(item.total, 2)}
                {item.unit}
              </Flex>
            )}
          </Flex>
          {item.total && (
            <Progress currentValue={item.currentValue} total={item.total} />
          )}
        </GridItem>
      ))}
    </Grid>
  </Flex>
);

export default OverviewPlane;
