import { Box, Flex, Text } from '@chakra-ui/react';

import { A7Label } from 'components/api7-components';

const STATUS_MAPPER = [
  { name: 'Pending', value: 1, labelType: 'orange' },
  { name: 'Creating', value: 2, labelType: 'yellow' },
  { name: 'Normal', value: 3, labelType: 'green' },
  { name: 'Create failed', value: 4, labelType: 'red' },
  { name: 'Deleting', value: 5, labelType: 'red' },
  { name: 'Deleted', value: 6, labelType: 'red' },
] as const;

type Props = {
  data: { name: string; value: string | number }[];
};

const ControlPanelPlane: React.FC<Props> = ({ data = [] }) => {
  const status = data.find(
    (item) => item.name.toLowerCase() === 'status'
  )?.value;
  const isCreatingPlane = status === 2;

  return (
    <Box border="1px #F0F0F0 solid" borderRadius="4px" width="full">
      {isCreatingPlane ? (
        <Flex
          width="full"
          h="240px"
          alignItems="center"
          justifyContent="center"
          backgroundImage="/cp-not-ready.svg"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
        >
          <Text fontWeight="medium" fontSize="xl" color="A7Gray.600">
            Please wait for a while, your service is being deployed.
          </Text>
        </Flex>
      ) : (
        data.map((item, index) => (
          <Flex
            height="56px"
            backgroundColor={index % 2 !== 0 ? 'A7Gray.50' : 'none'}
            key={item.name}
          >
            <Flex
              alignItems="center"
              ml="24px"
              w="172px"
              color="A7Gray。800"
              lineHeight="6"
            >
              {item.name}
            </Flex>
            <Flex alignItems="center" color="A7Gray.900" fontWeight="medium">
              {item.name.toLowerCase() !== 'status' ? (
                item.value
              ) : (
                <A7Label
                  color={
                    STATUS_MAPPER.find((i) => i.value === item.value)
                      ?.labelType || 'green'
                  }
                >
                  {STATUS_MAPPER.find((i) => i.value === item.value)?.name}
                </A7Label>
              )}
            </Flex>
          </Flex>
        ))
      )}
    </Box>
  );
};

export default ControlPanelPlane;
