import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  InfoOutlineIcon,
  QuestionOutlineIcon,
  WarningTwoIcon,
} from '@chakra-ui/icons';
import {
  Flex,
  Box,
  Text,
  useDisclosure,
  Center,
  Link as ChakraLink,
  Tooltip,
} from '@chakra-ui/react';
import Link from 'next/link';
import Skeleton from 'react-loading-skeleton';
import { Column } from 'react-table';
import Image from 'next/image';
import moment from 'moment';

import {
  A7Label,
  A7Header,
  A7Table,
  A7Button,
} from 'components/api7-components';
import TimeFormat from 'components/time-format';
import useCluster from 'hooks/useCluster';
import useUserPlan from 'hooks/useUserPlan';
import useAccess from 'hooks/useAccess';
import { TourContext } from 'hooks/useTour';
import { InstanceDetails, InstanceStatus } from 'types/Instance';

import useQueryHandle from '@/components/GlobalSearch/hooks/useQueryHandle';
import { showToast } from '@/helper/utils';
import InstanceView, { InstanceStatusMap } from './components/InstanceViewer';
import StatusHelperModal from './StatusHelperModal';
import OverviewPlane from './components/OverviewPlane';
import OverviewSkeleton from './components/OverviewSkeleton';
import ControlPanelSkeleton from './components/ClusterSkeleton';
import ControlPanelPlane from './components/Cluster';
import useClusterInstanceList from './useClusterInstanceList';

const OverView: React.FC = () => {
  const { data, isLoading } = useUserPlan();

  const overViewData = useMemo(() => {
    if (data) {
      const plan = data.plan_name;
      return [
        {
          name: 'Members',
          currentValue: data.current_members,
        },
        {
          name: 'Services',
          currentValue: data.current_applications,
          total: plan === 'free' ? data.max_applications : undefined,
        },
        {
          name: 'Routes',
          currentValue: data.current_apis,
        },
        {
          name: 'API Calls',
          currentValue: data.current_api_calls,
          total: plan === 'free' ? data.max_api_calls : undefined,
        },
      ];
    }
    return undefined;
  }, [data]);

  return (
    <Box>
      <A7Header title="Overview" />

      <Box>
        {isLoading && <OverviewSkeleton />}
        {!isLoading && overViewData && <OverviewPlane data={overViewData} />}
      </Box>
    </Box>
  );
};

const ControlPanel: React.FC = () => {
  const { data, isLoading } = useCluster();

  const planeData = useMemo(() => {
    if (data) {
      const { list } = data;
      return [
        {
          name: 'Status',
          value: list[0].status,
        },
        {
          name: 'Created Time',
          value: new Date(list[0].created_at).toLocaleString('en-US'),
        },
        {
          name: 'Last Update Time',
          value: new Date(list[0].updated_at).toLocaleString('en-US'),
        },
      ];
    }
    return undefined;
  }, [data]);

  return (
    <Box>
      <Box>
        <A7Header title="Cluster" />
      </Box>
      {isLoading && <ControlPanelSkeleton />}
      {!isLoading && planeData && <ControlPanelPlane data={planeData} />}
    </Box>
  );
};

const GatewayInstance: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { clusterId, isClusterReady } = useCluster();

  const {
    isOpen: instanceModalOpen,
    onOpen: instanceModalOnOpen,
    onClose: instanceOnClose,
  } = useDisclosure();
  const [detailData, setDetailData] = useState<InstanceDetails>();
  const { setIsStepRun, focusIndex, stepIndex, tourIsOpen, isStepRun } =
    useContext(TourContext);

  const { data, pagination, isLoading, isValidating, reload, onParamsChange } =
    useClusterInstanceList();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (tourIsOpen && [1, 2].includes(focusIndex) && stepIndex === 1) {
      timer = setTimeout(() => {
        setIsStepRun(true);
      }, 1000);
    }
    if (tourIsOpen && focusIndex === 1 && stepIndex === 3 && !isStepRun) {
      timer = setTimeout(() => {
        setIsStepRun(true);
      }, 1000);
    }
    // Task2: click step 3 prev button
    if (tourIsOpen && focusIndex === 2 && stepIndex === 1 && !isStepRun) {
      timer = setTimeout(() => {
        setIsStepRun(true);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const showInstanceDetail = (instanceData: InstanceDetails) => {
    setDetailData(instanceData);
    instanceModalOnOpen();
  };

  useQueryHandle({
    resourceType: 'Gateway Instances',
    handle: (query) => {
      if (data) {
        const index = data?.findIndex((item) => item.id === query);
        if (index !== -1) {
          showInstanceDetail(data[index]);
        } else {
          showToast({
            title: 'Gateway instance does not exist',
          });
        }
      }
    },
    dependency: [data],
  });

  const columns: Column[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        sticky: 'left',
        Cell: (props) => {
          const { value, row } = props;
          const instanceItem = row.original as InstanceDetails;
          return (
            <ChakraLink
              onClick={() => {
                showInstanceDetail(instanceItem);
              }}
              color="A7Blue.600"
            >
              {value}
            </ChakraLink>
          );
        },
      },
      {
        Header: 'VERSION',
        accessor: 'version',
        customShowSort: true,
      },
      {
        Header: (
          <Flex>
            STATUS
            <QuestionOutlineIcon
              ml="5px"
              w="14px"
              h="14px"
              cursor="pointer"
              onClick={onOpen}
            />
          </Flex>
        ),
        accessor: 'status',
        Cell: (props) => {
          const { value }: { value: InstanceStatus } = props;
          return (
            <A7Label color={InstanceStatusMap[value].color}>
              {InstanceStatusMap[value].text}
            </A7Label>
          );
        },
      },
      {
        Header: 'HOSTNAME',
        accessor: 'hostname',
        Cell: ({ value }) => <Text>{value}</Text>,
      },
      {
        Header: 'UPTIME',
        accessor: 'uptime',
        customShowSort: true,
        Cell: ({ value }) => {
          const duration = moment.duration(value);
          return moment.duration(duration)?.humanize();
        },
      },
      {
        Header: 'LAST REPORTED TIME',
        accessor: 'last_seen_time',
        customShowSort: true,
        Cell: ({ value }) => <TimeFormat time={value} />,
      },
      {
        Header: (
          <Flex>
            CERTIFICATE EXPIRY TIME
            <Tooltip
              hasArrow
              label="The expiry time of the TLS certificate used by your gateway instances to setup safe connections to API7 Cloud. Please renew the certificate in time if it’s about to expire."
              placement="right"
              bg="A7Gray.900"
            >
              <InfoOutlineIcon
                ml="4px"
                w="14px"
                h="14px"
                data-testid="labelTooltip"
              />
            </Tooltip>
          </Flex>
        ),
        accessor: 'certificate_expire_time',
        customShowSort: true,
        sticky: 'right',
        Cell: ({ value }) => {
          const nowTime = new Date();
          const isExpiredIn30Days = value
            ? moment(value).diff(nowTime, 'seconds') < 30 * 24 * 60 * 60 &&
              moment(value).diff(nowTime, 'seconds') > 0
            : false;
          const isExpired = value
            ? moment(value).diff(nowTime, 'seconds') < 0
            : false;

          return value ? (
            <Flex>
              <Center mr="8px">
                {isExpired && (
                  <Tooltip
                    hasArrow
                    label="This TLS certificate is expired, please renew it in time."
                    placement="right"
                    bg="A7Gray.900"
                  >
                    <WarningTwoIcon
                      ml="4px"
                      w="14px"
                      h="14px"
                      data-testid="labelTooltip"
                      color="A7Red.500"
                    />
                  </Tooltip>
                )}
                {isExpiredIn30Days && (
                  <Tooltip
                    hasArrow
                    label="This TLS certificate will expire in 30 days, please renew it in time."
                    placement="right"
                    bg="A7Gray.900"
                  >
                    <WarningTwoIcon
                      ml="4px"
                      w="14px"
                      h="14px"
                      data-testid="labelTooltip"
                      color="A7Yellow.500"
                    />
                  </Tooltip>
                )}
              </Center>
              <TimeFormat time={value} />
            </Flex>
          ) : (
            <Text>UNKNOWN</Text>
          );
        },
      },
    ],
    [onOpen]
  );

  return (
    <Box className="card-container">
      <InstanceView
        isOpen={Boolean(instanceModalOpen && detailData)}
        onClose={instanceOnClose}
        data={detailData}
      />
      <StatusHelperModal isOpen={isOpen} onClose={onClose} />
      <Flex justifyContent="space-between">
        <Center>
          <A7Header title="Gateway Instances" />
        </Center>
        {Boolean(!clusterId) && <Skeleton width="142px" height="32px" />}
        {Boolean(clusterId) && (
          <Tooltip
            label="Cluster is not ready."
            hasArrow
            placement="top"
            offset={[0, -10]}
            isDisabled={isClusterReady}
          >
            <Flex>
              <Center mr="16px">
                <A7Button
                  isLoading={isValidating}
                  onClick={reload}
                  isDisabled={!isClusterReady}
                >
                  <Image width="18px" height="18px" src="/icons/refresh.svg" />
                </A7Button>
              </Center>
              <Center id="add-instance">
                <Link href="/overview/instances" passHref>
                  <A7Button
                    type="add"
                    disabled={!isClusterReady}
                    onClick={() => setIsStepRun(false)}
                  >
                    <Text fontWeight="medium" fontSize="sm" lineHeight="5">
                      Add Instances
                    </Text>
                  </A7Button>
                </Link>
              </Center>
            </Flex>
          </Tooltip>
        )}
      </Flex>
      <Box>
        <A7Table
          columns={columns}
          data={data}
          isLoading={isLoading}
          isValidating={isValidating}
          serverSort
          onParamsChange={onParamsChange}
          pagination={pagination}
          reload={reload}
          noDataText="Not connected to Instance yet."
        />
      </Box>
    </Box>
  );
};

const Page = () => {
  const { getAccess } = useAccess();
  return (
    <Flex
      color="black"
      height="auto"
      w="full"
      alignItems="center"
      flexDirection="column"
    >
      <Box className="card-container">
        <OverView />
      </Box>
      <Box className="card-container">
        <ControlPanel />
      </Box>
      {getAccess('cluster.cluster.get') && (
        <Box width="full" id="gateway-instances">
          <GatewayInstance />
        </Box>
      )}
    </Flex>
  );
};

export default Page;
