import React from 'react';

import { Box } from '@chakra-ui/react';
import moment from 'moment';

type Props = {
  time: string;
};

const TimeFormat: React.FC<Props> = ({ time }) => (
  <Box minW="152px">{moment(time).format('YYYY-MM-DD HH:mm')}</Box>
);

export default TimeFormat;
