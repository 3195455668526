import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { QUERY_PARAM, RESOURCE_PARAM } from '../constants';
import { ResourceType } from '../types';

const useQueryHandle = ({
  resourceType,
  handle,
  dependency = [],
}: {
  resourceType: ResourceType;
  handle: (query: string) => void;
  dependency?: unknown[];
}) => {
  const router = useRouter();
  const { query } = router;

  // Execute the `handle` method when both `QUERY_PARAM` and `RESOURCE_PARAM` are present in the route query
  useEffect(() => {
    if (query[QUERY_PARAM] && query[RESOURCE_PARAM]) {
      if (resourceType === query[RESOURCE_PARAM]) {
        handle(query.query as string);

        // After execution, remove `QUERY_PARAM` and `RESOURCE_PARAM` from the route, ensuring that handle is executed only once
        const timmer = setTimeout(() => {
          const filteredQuery = Object.keys(query)
            .filter((key) => key !== QUERY_PARAM && key !== RESOURCE_PARAM)
            .reduce((acc, key) => ({ ...acc, [key]: query[key] }), {});
          router.replace({ pathname: router.pathname, query: filteredQuery });
        }, 0);

        return () => {
          clearTimeout(timmer);
        };
      }
    }
  }, [...dependency, router.query]);
};

export default useQueryHandle;
