import axios from 'axios';
import useSWR from 'swr';

import useUser from './useUser';

const useUserPlan = () => {
  const { orgId, isLoading: isUserLoading } = useUser();
  const { data, error, isLoading } = useSWR<Plan.Res>(
    orgId ? `orgs/${orgId}/plan/status` : null,
    (url: string) => axios.get(url).then((res) => res.data.payload)
  );

  return {
    data,
    isLoading: isLoading || isUserLoading,
    isError: error,
  };
};

export default useUserPlan;
