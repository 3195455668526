import { useRouter } from 'next/router';
import { useState, useCallback } from 'react';

const usePagination = (
  params: { savePage?: boolean } = { savePage: false }
) => {
  const savePage = params?.savePage;
  const router = useRouter();

  const { page } = router.query;
  const initPage = Number(page) - 1 > 0 ? Number(page) - 1 : 0;
  const [pageIndex, setPageIndex] = useState(savePage ? initPage : 0);
  const [search, setSearch] = useState<string>();

  const [pageSize] = useState(10);
  const gotoPage = useCallback(
    (pageIndex: number) => {
      setPageIndex(pageIndex);
      if (savePage) {
        router.replace({
          query: { ...router.query, page: pageIndex + 1, search },
        });
      }
    },
    [router, search]
  );

  return { pageIndex, pageSize, gotoPage, setSearch };
};

export default usePagination;
