import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Flex,
  Center,
  Link as ChakraLink,
  SimpleGrid,
} from '@chakra-ui/react';
import moment from 'moment';

import { A7Label } from 'components/api7-components';
import { InstanceDetails } from 'types/Instance';

export const InstanceStatusMap = {
  Healthy: {
    text: 'Healthy',
    color: 'green',
  },
  'Lost Connection': {
    text: 'Lost Connection',
    color: 'orange',
  },
  Offline: {
    text: 'Offline',
    color: 'red',
  },
  'Only Heartbeats': {
    text: 'Only Heartbeats',
    color: 'yellow',
  },
} as const;

type ViewProps = {
  data?: InstanceDetails;
  isOpen: boolean;
  onClose: () => void;
};

const ViewInstance: React.FC<ViewProps> = ({ isOpen, onClose, data = {} }) => {
  const {
    id,
    status,
    version,
    ip,
    last_seen_time,
    hostname,
    uptime,
    register_time,
    ports,
  } = data;
  const infoDetail = {
    Version: version,
    'IP Address': ip,
    Hostname: hostname,
    Uptime: moment.duration(uptime)?.humanize(),
    'Last Report Time': moment(last_seen_time).format('YYYY-MM-DD HH:mm'),
    'Register Time': moment(register_time).format('YYYY-MM-DD HH:mm'),
    Ports: (ports || []).length ? ports!.join(', ') : 'Unknown',
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent width="556px" borderRadius="4px">
        <ModalCloseButton />
        <ModalHeader
          color="A7Gray.700"
          fontWeight="medium"
          fontSize="lg"
          lineHeight="7"
          height="60px"
        >
          Gateway Instance Details
        </ModalHeader>
        <Divider />
        <ModalBody p="24px">
          <Box>
            <Flex mb="16px">
              <Center
                height="24px"
                fontWeight="medium"
                lineHeight="5"
                mr="12px"
              >
                {id}
              </Center>
              {Boolean(status) && (
                <Center height="24px">
                  {' '}
                  <A7Label color={InstanceStatusMap[status!]?.color}>
                    {InstanceStatusMap[status!].text}
                  </A7Label>
                </Center>
              )}
            </Flex>
            <ChakraLink
              href={`/monitoring?instanceID=${id}`}
              color="A7Blue.600"
              isExternal
              textDecoration="underline"
              textDecorationColor="A7Blue.600"
            >
              Redirect to Monitoring Page{' '}
              <span style={{ letterSpacing: '-5px' }}>{`>>`}</span>
            </ChakraLink>
          </Box>
          <Box mt="40px">
            <SimpleGrid minChildWidth="200px" spacing="24px">
              {Object.keys(infoDetail).map((key) => (
                <Flex
                  key={key}
                  minHeight="40px"
                  direction="column"
                  justify="space-between"
                >
                  <Box fontWeight="semibold" fontSize="xs" color="A7Gray.500">
                    {key}
                  </Box>
                  <Box fontSize="sm" color="A7Gray.800" mt="4px" width="full">
                    {infoDetail[key as keyof typeof infoDetail]}
                  </Box>
                </Flex>
              ))}
            </SimpleGrid>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewInstance;
